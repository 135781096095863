import "react-dice-complete/dist/react-dice-complete.css"

import React, { useEffect, useRef, useState } from "react"
import { faBolt, faSpinner } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LayoutCekilis from "components/layout-cekilis"
import { RaffleTypes } from "components/layout-cekilis"
import SEO from "components/seo"
import useTranslations from "components/use-translations"

export default function RastgeleSayi() {
  const [count, setCount] = useState(0)
  const [diceText, setDiceText] = useState({ __html: "" })
  const [total, setTotal] = useState(0)
  const audioRef = useRef({ current: null })
  const audioIsAvalible = audioRef.current && audioRef.current.volume

  useEffect(() => {
    if (audioIsAvalible) audioRef.current.src = "/sounds/dice.mp3"
  }, [])

  const rollDice = runCount => {
    let result = ""
    let total = 0
    for (let i = 0; i < count; i++) {
      const randomNumber = Math.floor(Math.random() * 6)
      total += randomNumber + 1
      result += `&#x268${randomNumber};`
    }
    setDiceText({ __html: result })

    if (runCount === 0) audioRef.current.play()
    if (runCount < 10) setTimeout(() => rollDice(runCount + 1), 120)
    else setTotal(total)
  }
  const {
    cekilis: { zarSeoTitle, zarSeoDesc, zarAt, atilacakZarSayisi, toplam },
  } = useTranslations()
  return (
    <>
      <LayoutCekilis headerText={zarAt} type={RaffleTypes.Zar}>
        <SEO
          title={zarSeoTitle}
          description={zarSeoDesc}
          pathname="https://www.kodvizit.com/cekilis/zar"
          datePublished="2017-02-08T22:39:04+00:00"
          dateModified="2020-08-05T16:53:13+00:00"
        />
        <div className="d-flex flex-column ">
          <audio ref={audioRef} />
          <div
            className="text-center mb-3 font-weight-bold"
            style={{ fontSize: 16 }}
          >
            {atilacakZarSayisi} :
          </div>
          <div className="d-flex flex-row justify-content-center  ">
            <input
              style={{ maxWidth: 90 }}
              type="number"
              max="50"
              min="1"
              value={count}
              onChange={e => setCount(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                if (total === -1) return
                if (audioIsAvalible) audioRef.current.src = "/sounds/dice.mp3"
                audioRef.current.currentTime = 0
                audioRef.current.play()
                setTotal(-1)
                rollDice(0)
              }}
              className={`btn btn-info mb-4 mt-3`}
            >
              <FontAwesomeIcon icon={faBolt} className="mr-1" />
              <span>{zarAt}</span>
            </button>
          </div>
          {
            <div className="mx-auto" style={{ marginBottom: 120 }}>
              {count !== 0 && total !== 0 && (
                <div className="my-1 text-center text-muted">
                  {toplam}:{" "}
                  {total === -1 ? (
                    <FontAwesomeIcon icon={faSpinner} pulse />
                  ) : (
                    total
                  )}
                </div>
              )}
              <d
                id="dice"
                style={{ fontSize: 120, overflowWrap: "anywhere" }}
                dangerouslySetInnerHTML={diceText}
              />
            </div>
          }
        </div>
      </LayoutCekilis>
    </>
  )
}
